import React, { useEffect, createRef } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "@components/layout";
import MonetizeAsset from "@modules/monetize-asset";
import PublisherAdvantage from "@modules/publisher-advantage";
import PublishersAndAdvertisers from "@modules/publishers-and-advertisers";
import Feedback from "@modules/feedback";
import Partnership from "@modules/partnership";
import GetStarted from "@modules/get-started";
import { applyFadeInAnimation } from "@helpers/animations";
import { ScrollTrigger } from "gsap/all";

const Publishers = () => {
  const {
    markdownRemark: {
      frontmatter: { pageName, partnership, getStarted },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/publishers/" }) {
          frontmatter {
            pageName
            partnership {
              backgroundColor
              smallHeader
              largeHeader
            }
            getStarted {
              backgroundColor
              blockBackground
              buttonLabel
              message
            }
          }
        }
      }
    `
  );

  const modules = [
    <MonetizeAsset />,
    <PublisherAdvantage />,
    <PublishersAndAdvertisers />,
    <Feedback />,
    <Partnership data={partnership} />,
  ];
  const moduleRefs = modules.map(() => createRef());

  useEffect(() => {
    moduleRefs.forEach(moduleRef => applyFadeInAnimation(moduleRef));
    return () => ScrollTrigger.getAll().forEach(t => t.kill());
  });

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      {modules.map((module, index) => (
        <div ref={moduleRefs[index]} key={index}>
          {module}
        </div>
      ))}
      <GetStarted {...getStarted} />
    </Layout>
  );
};

export default Publishers;
