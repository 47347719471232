import React from "react";

const SliderArrow = ({ width = "42", height = "42" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 77 77"
  >
    <g transform="translate(-1057 -767)">
      <circle
        cx="38.5"
        cy="38.5"
        r="38.5"
        transform="translate(1057 767)"
        fill="#231f20"
      />
      <path
        d="M-10740.7,17249.557h24.3v-24.268"
        transform="translate(6731.66 20312.879) rotate(132)"
        fill="none"
        stroke="#faf8f9"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default SliderArrow;
