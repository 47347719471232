import React, { createRef, useEffect } from "react";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import * as Colors from "@constants/colors";
import { applyHighlightAnimation } from "@helpers/animations";
import SliderArrow from "@svg-icons/slider-arrow";
import ButtonLink from "@components/button-link";
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  LargeHeader,
  Info,
  InfoBlock,
  PhoneContainer,
  SliderContainer,
  Slide,
  SliderArrowContainer,
  StyledImage,
  Highlight,
} from "./monetize-asset.styled";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  prevArrow: (
    <SliderArrowContainer>
      <SliderArrow />
    </SliderArrowContainer>
  ),
  nextArrow: (
    <SliderArrowContainer isRight>
      <SliderArrow />
    </SliderArrowContainer>
  ),
};

const MonetizeAsset = () => {
  const {
    markdownRemark: {
      frontmatter: {
        introBlock: {
          backgroundColor,
          buttonLabel,
          description,
          largeHeader,
          sliderData,
          smallHeader,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/publishers/" }) {
          frontmatter {
            introBlock {
              backgroundColor
              buttonLabel
              description
              largeHeader {
                isHighlighted
                text
              }
              sliderData {
                imageUrl {
                  publicURL
                  childImageSharp {
                    fixed(width: 201, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
              smallHeader
            }
          }
        }
      }
    `
  );

  const highlights = largeHeader.filter(({ isHighlighted }) => isHighlighted);
  const highlightRefs = highlights.map(() => createRef());
  let highlightCount = -1;

  useEffect(() => {
    const tweens = highlightRefs.map(ref => {
      return applyHighlightAnimation(ref.current, Colors.beachTeal);
    });

    return () => {
      tweens.forEach(tween => tween.kill());
    };
  });

  return (
    <Container backgroundColor={backgroundColor}>
      <Wrapper>
        <Content>
          <InfoBlock>
            {smallHeader && (
              <BlockSupTitle color={Colors.charcoal}>
                {smallHeader}
              </BlockSupTitle>
            )}
            <LargeHeader>
              {largeHeader.map(({ text, isHighlighted }) => {
                if (isHighlighted === true) {
                  highlightCount += 1;
                  return (
                    <Highlight
                      ref={highlightRefs[highlightCount]}
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  );
                } else {
                  return (
                    <span
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  );
                }
              })}
            </LargeHeader>
            <Info
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
            <ButtonLink to="/contact" text={buttonLabel} />
          </InfoBlock>
          <PhoneContainer>
            <SliderContainer>
              <Slider {...settings}>
                {sliderData.map(({ imageUrl, alt }) => (
                  <Slide key={alt}>
                    <StyledImage
                      image={imageUrl}
                      isFixed
                      width="100%"
                      height="100%"
                      alt={alt}
                    />
                  </Slide>
                ))}
              </Slider>
            </SliderContainer>
          </PhoneContainer>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default MonetizeAsset;
