import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "@components/image";
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import {
  Container,
  StyledBlockTitle,
  DesktopCircleContainer,
  MobileCircleContainer,
  Description,
} from "./publishers-and-advertisers.styled";

const PublishersAndAdvertisers = () => {
  const {
    markdownRemark: {
      frontmatter: {
        publishersAndAdvertisers: {
          backgroundColor,
          smallHeader,
          largeHeader,
          imageDesktop,
          imageMobile,
          alt,
          imageLabel,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/publishers/" }) {
          frontmatter {
            publishersAndAdvertisers {
              backgroundColor
              smallHeader
              largeHeader
              imageDesktop {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 868, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageMobile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 374, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              imageLabel
            }
          }
        }
      }
    `
  );

  return (
    <Container backgroundColor={backgroundColor}>
      <Wrapper>
        {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
        <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
        <DesktopCircleContainer>
          <Image image={imageDesktop} alt={alt} />
        </DesktopCircleContainer>
        <MobileCircleContainer>
          <Image image={imageMobile} alt={alt} />
        </MobileCircleContainer>
        <Description>{imageLabel}</Description>
      </Wrapper>
    </Container>
  );
};

export default PublishersAndAdvertisers;
