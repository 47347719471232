import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { BlockTitle } from "@components/shared.styled";

const Container = styled.section`
  background-color: ${({ backgroundColor = Colors.charcoal }) =>
    backgroundColor};
  text-align: center;
  padding: 112px 0;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  color: ${Colors.white};
  max-width: 520px;
  margin: 0 auto 48px;

  @media (max-width: ${Screens.md}) {
    margin-bottom: 40px;
  }
`;

const DesktopCircleContainer = styled.div`
  max-width: 868px;
  margin: 0 auto;

  @media (max-width: ${Screens.lg}) {
    display: none;
  }
`;

const MobileCircleContainer = styled.div`
  display: none;

  @media (max-width: ${Screens.lg}) {
    display: block;
    max-width: 374px;
    margin: 0 auto;
  }
`;

const Description = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  line-height: 36px;
  width: 100%;
  margin: 48px 0 0;

  @media (max-width: ${Screens.md}) {
    margin-top: 40px;
  }
`;

export {
  Container,
  StyledBlockTitle,
  DesktopCircleContainer,
  MobileCircleContainer,
  Description,
};
