import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { Quote } from "@components/shared.styled";
import {
  PhotoContainer,
  PersonName,
  PersonDescription,
} from "@components/person-card/person-card.styled";
import { BlockTitle } from "@components/shared.styled";

const Container = styled.section`
  background-color: ${({ containerBackground = Colors.offWhite }) =>
    containerBackground};
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.md}) {
    flex-wrap: wrap;
  }

  ${PhotoContainer} {
    width: 67px;
    height: 67px;
    margin-right: 29px;
  }

  ${PersonName},
  ${PersonDescription} {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    max-width: 340px;
    color: ${Colors.charcoal};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 112px 20px 112px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: flex-end;

  @media (max-width: 1200px) {
    padding-top: 40px;
  }

  @media (max-width: ${Screens.lg}) {
    padding: 50px 20px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 620px;
  width: 100%;
  display: flex;

  @media (max-width: ${Screens.md}) {
    max-width: 100%;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin: 0 0 32px 0;
  line-height: 1.5;
  width: 100%;
  max-width: 620px;
  padding-left: 86px;

  @media (max-width: ${Screens.lg}) {
    padding-left: 64px;
  }

  @media (max-width: ${Screens.md}) {
    font-size: 28px;
  }

  @media (max-width: ${Screens.xs}) {
    padding-left: 31px;
  }
`;

const StyledQuote = styled(Quote)`
  line-height: 150px;
  margin-right: 20px;

  @media (max-width: ${Screens.lg}) {
    font-size: 100px;
    line-height: 100px;
  }

  @media (max-width: ${Screens.xs}) {
    font-size: 60px;
    line-height: 60px;
    margin-right: 5px;
  }
`;

const Heading = styled(BlockTitle)``;

const FeedbackText = styled.div`
  margin: 0 0 40px;
  position: relative;
  font-size: 24px;
  line-height: 36px;
  max-width: 360px;

  @media (max-width: ${Screens.lg}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ImageContainer = styled.div`
  max-width: 42.25%;
  width: 100%;

  @media (max-width: ${Screens.md}) {
    order: 1;
    max-width: 100%;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

export {
  Container,
  Content,
  ContentWrapper,
  StyledBlockTitle,
  Heading,
  StyledQuote,
  FeedbackText,
  ImageContainer,
  Image,
};
