import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "@components/image";
import { BlockSupTitle } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  ImageContainer,
  Content,
  ContentWrapper,
  DescriptionBlock,
  StyledBlockTitle,
  StyledBlockDescription,
} from "./publisher-advantage.styled";

const PublisherAdvantage = () => {
  const {
    markdownRemark: {
      frontmatter: {
        publisherAdvantage: {
          backgroundColor,
          description,
          imageUrl,
          alt,
          smallHeader,
          largeHeader,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/publishers/" }) {
          frontmatter {
            publisherAdvantage {
              backgroundColor
              description
              imageUrl {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              smallHeader
              largeHeader
            }
          }
        }
      }
    `
  );

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <Image height="100%" image={imageUrl} alt={alt} />
      </ImageContainer>
      <Content>
        <ContentWrapper>
          <DescriptionBlock>
            {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
            <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
            <StyledBlockDescription
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
          </DescriptionBlock>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

export default PublisherAdvantage;
