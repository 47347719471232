import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import * as Colors from "@constants/colors";
import PersonCard from "@components/person-card";
import Image from "@components/image";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  ContentWrapper,
  StyledBlockTitle,
  StyledQuote,
  FeedbackText,
  ImageContainer,
} from "./feedback.styled";

const Feedback = () => {
  const {
    markdownRemark: {
      frontmatter: {
        feedback: {
          author,
          comment,
          backgroundColor,
          imageUrl,
          alt,
          largeHeader,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/publishers/" }) {
          frontmatter {
            feedback {
              author {
                description
                name
                photo {
                  publicURL
                  childImageSharp {
                    fixed(width: 67, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                displayPhoto
                displayDescription
                displayPosition
                position
              }
              alt
              backgroundColor
              comment
              imageUrl {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              largeHeader
            }
          }
        }
      }
    `
  );

  const {
    displayPhoto,
    displayDescription,
    displayPosition,
    name,
    photo,
    position,
    description,
  } = author;

  const visibleAuthorProps = {
    name,
    ...(displayPhoto && { photo }),
    ...(displayDescription && { description }),
    ...(displayPosition && { position }),
  };

  return (
    <Container backgroundColor={backgroundColor}>
      <Content>
        <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
        <ContentWrapper>
          <StyledQuote>“</StyledQuote>
          <div>
            <FeedbackText
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(comment),
              }}
            />
            <PersonCard
              person={{ ...visibleAuthorProps, textColor: Colors.charcoal }}
            />
          </div>
        </ContentWrapper>
      </Content>
      <ImageContainer>
        <Image height="100%" image={imageUrl} width="100%" alt={alt} />
      </ImageContainer>
    </Container>
  );
};

export default Feedback;
