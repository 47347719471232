import styled, { keyframes } from "styled-components";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { ButtonLink } from "@components/button-link/button-link.styled";
import Image from "@components/image";

const slideAnimation = keyframes`
    0% { opacity: 0; transform: translateX(64px); }
    100% { opacity: 1.0; transform: translateX(0); }
`;

const Container = styled.section`
  background: ${({ backgroundColor = Colors.seafoam }) => backgroundColor};
  padding: 112px 0 112px;
  overflow: hidden;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0 165px;
    text-align: center;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ButtonLink} {
    color: ${Colors.white};
    border-color: ${Colors.charcoal};
    background: ${Colors.charcoal};

    :hover {
      background: ${Colors.blackLighter};
      border-color: ${Colors.blackLighter};
    }
  }

  @media (max-width: ${Screens.lg}) {
    flex-direction: column;
  }
`;

const InfoBlock = styled.div`
  max-width: 35%;
  margin-right: 10px;
  padding-top: 70px;

  @media (max-width: ${Screens.lg}) {
    max-width: 100%;
    padding: 0;
    margin: 0 0 60px;
  }
`;

const LargeHeader = styled.h1`
  display: inline-block;
  font-family: ${Fonts.ClearfaceFont};
  font-size: 48px;
  line-height: 62px;
  margin: 0 0 5px;

  @media (max-width: ${Screens.lg}) {
    font-size: 36px;
    line-height: 44px;
  }

  span {
    padding-right: 10px;
  }
`;

const Highlight = styled.span`
  background: linear-gradient(
    to right,
    ${Colors.goldenHourLight} 100%,
    transparent 0%
  );
  text-decoration: underline;
  padding: 0 10px;
`;

const Info = styled.div`
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  margin: 24px 0 48px;

  @media (max-width: ${Screens.lg}) {
    font-size: 22px;
    line-height: 30px;
    margin: 20px 0 40px;
  }
`;

const PhoneContainer = styled.div`
  max-width: 130px;
  height: 378px;
  width: 100%;
  position: relative;
  margin-bottom: 118px;
  animation: ${slideAnimation} 0.5s 1;

  svg {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  @media (max-width: ${Screens.lg}) {
    margin-bottom: 85px;
    max-width: 100%;
  }
`;

const Slide = styled.div`
  height: 100%;
  padding: 0 11px;
`;

const SliderContainer = styled.div`
  height: 435px;
  min-width: 223px;
  position: absolute;
  left: -300px;
  top: 30px;
  z-index: 1;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    width: 223px;
  }

  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: -90px;
    width: 42px;
    height: 42px;
  }

  .slick-arrow:hover {
    circle {
      fill: ${Colors.blackLighter};
    }
  }

  .slick-arrow:before,
  .slick-arrow:after {
    display: none;
  }

  .slick-prev {
    left: 300px;
  }

  .slick-next {
    left: 387px;
  }

  @media (max-width: ${Screens.lg}) {
    left: 0;
  }

  @media (max-width: ${Screens.md}) {
    .slick-arrow {
      width: 42px;
      height: 42px;
      bottom: -90px;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      left: calc(100vw - 40px - 42px - 11px);
    }
  }
`;

const SliderArrowContainer = styled.div`
  position: relative;

  ${({ isRight }) =>
    isRight ? `transform: rotate(180deg) translate(0, 50%);` : ""}
`;

const StyledImage = styled(Image)`
  border-radius: 8px;
`;

export {
  StyledImage,
  Container,
  Content,
  InfoBlock,
  LargeHeader,
  Info,
  PhoneContainer,
  SliderContainer,
  Slide,
  SliderArrowContainer,
  Highlight,
};
