import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { BlockTitle, BlockDescription } from "@components/shared.styled";

const Container = styled.section`
  background: ${({ backgroundColor = Colors.white }) => backgroundColor};
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.md}) {
    flex-wrap: wrap;
  }
`;

const ImageContainer = styled.div`
  max-width: 42.25%;
  width: 100%;

  @media (max-width: ${Screens.md}) {
    order: 1;
    max-width: 100%;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding-top: 112px;
  padding-bottom: 112px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${Screens.md}) {
    max-width: 100%;
    justify-content: flex-start;
  }
`;

const DescriptionBlock = styled.div`
  padding: 0 40px 0px 80px;

  @media (max-width: ${Screens.lg}) {
    max-width: 100%;
    padding: 0 20px 20px;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 32px;

  @media (max-width: ${Screens.lg}) {
    margin-bottom: 20px;
  }
`;

const StyledBlockDescription = styled(BlockDescription)`
  max-width: 440px;
`;

export {
  Container,
  ImageContainer,
  Content,
  ContentWrapper,
  DescriptionBlock,
  StyledBlockTitle,
  StyledBlockDescription,
};
